import * as yup from "yup";

import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { BlockButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { ButtonGroup } from "@frontend/common/src/elements/ButtonGroup/ButtonGroup.styles";
import Modal from "@frontend/common/src/components/Modal/Modal";
import { UpdateTrip } from "../../types/Trip";
import { useActions } from "../../overmind";
import { Shipment } from "../../types/Shipment";

type Props = {
  trip: Shipment;
  onClose: () => void;
};

const validationSchema = yup.object().shape({
  driver: yup.string(),
  driverPhone: yup.string(),
});

export const EditTripModal: FunctionComponent<Props> = ({ trip, onClose }) => {
  const actions = useActions();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateTrip>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      shipmentId: trip.shipmentId,
      driver: trip.driver,
      driverPhone: trip.driverPhone,
    },
  });

  const onSubmitData = async (data: UpdateTrip) => {
    // eslint-disable-next-line no-param-reassign
    data.shipmentId = trip.shipmentId;
    await actions.updateTrip(data);
    onClose();
  };

  return (
    <Modal isOpen onClose={() => onClose()}>
      <h2>{trip.shipmentId}</h2>

      <form style={{ padding: "24px 24px 0 24px", height: "auto" }}>
        <div style={{ maxWidth: "25rem" }}>
          <FormInput
            htmlFor="driver"
            name="driver"
            label="Sjåfør"
            type="text"
            placeholder="Sjåfør"
            defaultValue={trip.driver}
            inputRef={register}
            error={errors.driver?.message}
          />
          <FormInput
            htmlFor="driverPhone"
            name="driverPhone"
            label="Telefon"
            type="text"
            placeholder="Telefon"
            defaultValue={trip.driver}
            inputRef={register}
            error={errors.driverPhone?.message}
          />
          <ButtonGroup>
            <BlockButton onClick={handleSubmit(onSubmitData)}>
              Lagre
            </BlockButton>
          </ButtonGroup>
        </div>
      </form>
    </Modal>
  );
};
