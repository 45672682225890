/* eslint-disable react/no-unescaped-entities */
import React, { FunctionComponent } from "react";
import { PreTreColors } from "webapp/src/styles/constants";
import { Package, ShipmentProject } from "../../types/Shipment";

type Props = {
  selectedDelivery: ShipmentProject;

  packages: Package[];
};
export const DeliveryModal: FunctionComponent<Props> = ({
  selectedDelivery,
  packages,
}) => {
  const drawPackageTable = (packageList: Package[]) => {
    return packageList.map((x, i) => {
      return (
        <tr
          style={{ background: i % 2 === 0 ? PreTreColors.borderlineGray : "" }}
        >
          <td>{x.packageNumber}</td>
          <td>{x.itemNumber}</td>
          <td>{x.productName}</td>
          <td>{x.shipmentStatus}</td>
          <td>{x.numPackages}</td>
          <td>{x.status}</td>
          <td>{x.b}</td>
          <td>{x.h}</td>
          <td>{x.l}</td>
        </tr>
      );
    });
  };
  return (
    <div>
      <p>
        <strong>Kontakt byggeplass</strong>
      </p>
      <p>Navn: {selectedDelivery.contactPerson}</p>
      <p>Telefon: {selectedDelivery.contactPhone}</p>
      <p>
        <strong>Merknad kjøring </strong>
      </p>
      <p>
        {selectedDelivery.markedDrive.length > 0
          ? selectedDelivery.markedDrive
          : "Ingen"}
      </p>
      <table style={{ overflowX: "auto" }}>
        <tbody>
          <tr>
            <th style={{ textAlign: "left" }}>Pakkenr</th>
            <th style={{ textAlign: "left" }}>Varenummer</th>
            <th style={{ textAlign: "left" }}>Produktnavn</th>
            <th style={{ textAlign: "left" }}>Forsendelsesstatus</th>
            <th style={{ textAlign: "left" }}>Antall kolli</th>
            <th style={{ textAlign: "left" }}>Status</th>
            <th style={{ textAlign: "left" }}>B</th>
            <th style={{ textAlign: "left" }}>H</th>
            <th style={{ textAlign: "left" }}>L</th>
          </tr>
          {drawPackageTable(packages)}
        </tbody>
      </table>
    </div>
  );
};
