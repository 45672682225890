import { Verb } from "@frontend/common/src/rest/RestClient";
import { parseISO } from "date-fns";
import axios from "axios";
import { RestClient } from "../rest/RestClient";

import { Package, Shipment, ShipmentProject } from "../types/Shipment";
import { UpdateTrip } from "../types/Trip";

const routes = {
  login: {
    path: "/koyreliste/logg-inn",
    verb: Verb.POST,
  },
  checkToken: {
    path: "/koyreliste/sjekk-token",
    verb: Verb.GET,
  },
  getTrips: {
    path: "/koyreliste/{year}/{fromWeek}/{toWeek}",
    verb: Verb.GET,
  },
  updateTrip: {
    path: "/koyreliste/update",
    verb: Verb.PUT,
  },
  getShipmentProjects: {
    path: "/koyreliste/projects/{shipmentId}",
    verb: Verb.GET,
  },
  getShipmentItems: {
    verb: Verb.GET,
    path: "/koyreliste/items/{shipmentId}/{projectId}",
  },
  getProjectsForMultipleShipments: {
    verb: Verb.POST,
    path: "/koyreliste/multipleProjects",
  },
};

export class TripService extends RestClient {
  cancelToken: any;

  async login(username: string, password: string): Promise<any> {
    const results: any = await this.makeRequest(routes.login, {
      Brukernavn: username,
      Passord: password,
    }).then((r) => r.data);
    // also returns transportUserDto
    return results.token;
  }

  async checkToken(): Promise<boolean> {
    return this.makeRequest(routes.checkToken).then((r) => r.data);
  }

  async updateTrip(trip: UpdateTrip): Promise<boolean> {
    return this.makeRequest(routes.updateTrip, trip).then((r) => r.data);
  }

  async getTrips(
    year: number,
    fromWeek: number,
    toWeek: number
  ): Promise<Shipment[]> {
    if (this.cancelToken !== undefined) {
      this.cancelToken.cancel();
    }
    this.cancelToken = axios.CancelToken.source();
    const trips: any[] = await this.makeRequest(
      routes.getTrips,
      {
        year,
        fromWeek,
        toWeek,
      },
      this.cancelToken
    ).then((r) => r.data);
    if (trips !== undefined) {
      trips.forEach((x) => {
        // eslint-disable-next-line no-param-reassign
        x.deliveryDay = parseISO(x.deliveryDay);
      });
    }
    this.cancelToken = undefined;
    return trips;
  }

  async getShipmentProjects(shipmentId: string): Promise<ShipmentProject[]> {
    if (this.cancelToken !== undefined) {
      this.cancelToken.cancel();
    }
    this.cancelToken = axios.CancelToken.source();
    return await this.makeRequest(routes.getShipmentProjects, {
      shipmentId,
    }).then((r) => r.data);
  }

  async getShipmentItems(
    shipmentId: string,
    projectId: string
  ): Promise<Package[]> {
    if (this.cancelToken !== undefined) {
      this.cancelToken.cancel();
    }
    this.cancelToken = axios.CancelToken.source();
    return await this.makeRequest(routes.getShipmentItems, {
      shipmentId,
      projectId,
    }).then((r) => r.data);
  }

  async getProjectsForMultipleShipments(
    shipments: string[]
  ): Promise<ShipmentProject[]> {
    if (this.cancelToken !== undefined) {
      this.cancelToken.cancel();
    }
    this.cancelToken = axios.CancelToken.source();
    return await this.makeRequest(
      routes.getProjectsForMultipleShipments,
      shipments
    ).then((r) => r.data);
  }
}
