/* eslint-disable import/no-duplicates */
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { createUrl } from "../app/pages";
import { Page } from "../types/Page";
import { Shipment } from "../types/Shipment";

const MyButton = styled.a`
  color: white;
  border-radius: 10px;
  background: #c24e44;
  margin-top: 1rem;
  border-radius: 10px;
  padding: 0.5rem;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
type DayPickerProps = {
  days: Date[];
  currentWeek: number;
  entries: Shipment[];
};
export const DayPicker: FunctionComponent<DayPickerProps> = ({
  days,
  currentWeek,
  entries,
}) => {
  return (
    <Container>
      {days.map((day: Date) => (
        <MyButton
          key={day.toString()}
          href={createUrl(Page.HOME, {
            week: currentWeek,
            day: day.toISOString().substring(0, 10),
          })}
        >
          Leveringsdag {format(day, "EEEE d. MMM", { locale: nb })}&nbsp; (
          {
            entries.filter(
              (x) => x.deliveryDay.getUTCDate() === day.getUTCDate()
            ).length
          }
          )
        </MyButton>
      ))}
    </Container>
  );
};
