/* eslint-disable import/no-duplicates */
import { format, getWeek } from "date-fns";
import { nb } from "date-fns/locale";
import { Shipment, ShipmentProject } from "../types/Shipment";

function openAsPageInNewTab(pageContent: string) {
  setTimeout(() => {
    const child = window.open("about:blank", "_blank");
    child.document.write(pageContent);
    child.document.close();
  });
}
const getHtmlDocument = (rowMarkup: string) => {
  return `
    <!DOCTYPE html>
    <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <title>PreTre</title>
        <style type="text/css">
        body {
        }
        
        table {
            font-family: arial;
            font-size: 8px;
            color: #333333;
            font-weight: normal;
            text-decoration: none;
            page-break-inside: auto;
        }
        
        th {
            font-family: arial;
            font-size: 8px;
            color: #333333;
            font-weight: bold;
            text-decoration: none;
            text-align: left;
            outline: thin solid black;
        }
        
        tr {
            page-break-inside: avoid;
            page-break-after: auto;
        }
        
        td.small {
            font-family: arial;
            font-size: 7px;
            color: #333333;
            font-weight: normal;
            text-decoration: none;
        }
        
        td.rightAlign {
            text-align: right;
        }
        
        
        tr.separator {
            height: 10px;
        }
        
        tr.grey {
            background-color: lightcyan;
        }
        
        td {
            outline: thin solid grey;
        }
        
        .headerDiv {
            font-family: Arial;
            font-size: 15px;
            color: #333333;
            font-weight: bold;
            text-decoration: none;
        }        
        </style>
        </head>
        <body onload="window.print();">
            <div id="mainDiv">
                <div id="headerDiv" class="headerDiv">
                    <span>Kjøreliste</span>
                </div>
                <div id="reportDiv" style="margin-top: 10px;">
                    <table id="reportTable" style="width: 100%;">
                        <tr>
                            <th style="width: 1%;">Uke</th>
                            <th style="width: 1%;">Kjøredato</th>
                            <th style="width: 1%;">Site</th>
                            <th style="width: 6%;">Forsendelsesid (Turnr)</th>
                            <th style="width: 2%;">Prosjektnr.</th>
                            <th style="width: 10%;">Merkes(Prosjektnavn)</th>
                            <th style="width: 6%;">Postnr/sted</th>
                            <th style="width: 8%;">Leveringsadresse</th>
                            <th style="width: 5%;">Kontaktperson byggeplass</th>
                            <th style="width: 6%;">Tlf Byggeplass</th>
                            <th style="width: 3%;">Volum</th>
                            <th style="width: 2%;">B</th>
                            <th style="width: 2%;">H</th>
                            <th style="width: 2%;">L</th>
                            <th>Transport</th>
                            <th style="width: 1%;">Kraning</th>
                            <th>Merknad kjøring</th>
                        </tr>
                        ${rowMarkup}
                    </table>
                </div>
            </div>
        </body>
    </html>
`;
};

const drawRow = (entry: Shipment, projects: ShipmentProject[]) => {
  const week = getWeek(entry.deliveryDay);
  let result = "";
  projects.forEach((x) => {
    result += `
    <tr>
    <td class="rightAlign">${week}</td>
    <td class="rightAlign">${format(entry.deliveryDay, "dd.MM.yyyy", {
      locale: nb,
    })}</td>
    <td class="rightAlign">${entry.site}</td>
    <td>${entry.shipmentId}</td>
    <td>${x.projID}</td>
    <td>${x.marked}</td>
    <td>${x.city}</td>
    <td>${x.address}</td>
    <td class="rightAlign">${x.contactPerson}</td>
    <td class="rightAlign">${x.contactPhone}</td>
    <td class="rightAlign">${x.volume}</td>
    <td class="rightAlign">${x.width}</td>
    <td class="rightAlign">${x.height}</td>
    <td class="rightAlign">${x.length}</td>   
    <td class="rightAlign">${x.transport}</td> 
    <td>${x.lifting ? "Ja" : "Nei"}</td>
    <td class="small">${x.markedDrive}</td>
</tr>
    `;
  });
  return result;
};

export const downloadAsHtml = (
  entries: Shipment[],
  projects: ShipmentProject[]
) => {
  if (entries.length === 0) return;
  const markup = entries
    .map((row) => {
      let str = "";

      str += drawRow(
        row,
        projects.filter((proj) => row.shipmentId === proj.shipmentId)
      );
      /*
      if (entries[index + 1] && entries[index + 1].levDag !== row.levDag) {
        str += `<tr class="separator"></tr>`;
      }
       */
      return str;
    })
    .reduce((all, row) => all + row);
  const doc = getHtmlDocument(markup);
  openAsPageInNewTab(doc);
};
