import { HomePage } from "../pages/Home/HomePage";
import { LoginPage } from "../pages/Login/LoginPage";
import { Page } from "../types/Page";

// eslint-disable-next-line no-shadow
export enum PageAccess {
  GUEST,
  AUTH,
}
type PageConfig = {
  component: any;
  restrictedTo: PageAccess | null;
  route: string;
};
export const pages: { [index: string]: PageConfig } = {
  [Page.LOGIN]: {
    component: LoginPage,
    restrictedTo: PageAccess.GUEST,
    route: "/logg-inn",
  },

  [Page.HOME]: {
    component: HomePage,
    restrictedTo: PageAccess.AUTH,
    route: "/turer/:week?/:day?/:trip?",
  },
};

export const getNavigationRoute = (page: Page): string => {
  const p = pages[page];

  if (!pages[page]) {
    throw new Error(`Page not found: ${page}`);
  }

  return p.route;
};

export const createUrl = (
  page: Page,
  params: { [index: string]: string | number } = null
): string => {
  const p = pages[page];
  if (!pages[page]) {
    throw new Error(`Page not found: ${page}`);
  }

  let { route } = p;
  if (params) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in params) {
      route = route.replace(`:${key}`, `${params[key]}`); // replace required key
      route = route.replace(`:${key}?`, `${params[key]}`); // replace optional key
    }
  }
  route = route.replace(/:.+\?/gi, "");
  route = route.replace(/\/\//gi, "/");
  route = route.replace(/\?/gi, ""); // remove trailing ?

  return route;
};
