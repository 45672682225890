import React, { FunctionComponent, useEffect } from "react";
import { useAppState, useEffects } from "../overmind";
import { Page } from "../types/Page";
import { pages, createUrl, PageAccess } from "./pages";

export const App: FunctionComponent = () => {
  const state = useAppState();
  const effects = useEffects();
  if (!state.isInitialLoadComplete) {
    return <div>Laster</div>;
  }
  const CurrentPage = pages[state.currentPage];
  if (!CurrentPage) {
    return <div>404</div>;
  }

  const isGuest = !state.user;
  useEffect(() => {
    if (isGuest && CurrentPage.restrictedTo === PageAccess.AUTH) {
      effects.toast("Du må logge inn for å se denne siden");
      effects.router.navigateTo(createUrl(Page.LOGIN));
    } else if (!isGuest && CurrentPage.restrictedTo === PageAccess.GUEST) {
      effects.router.navigateTo(createUrl(Page.HOME));
    }
  }, [isGuest, CurrentPage.restrictedTo, effects]);
  const CurrentPageComponent = CurrentPage.component;

  return <CurrentPageComponent />;
};

export default App;
