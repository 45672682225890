/* eslint-disable no-shadow */
import { getWeek } from "date-fns";
import { derived } from "overmind";
import { Package, Shipment, ShipmentProject } from "../types/Shipment";
import { Page } from "../types/Page";
import { Filter } from "../types/Filter";
import { Selection } from "../types/Selection";

type State = {
  // used to determine if app is ready to render
  isInitialLoadComplete: boolean;

  // currently selected week, day, trup
  selection: Selection;

  // filter used against API
  filter: Filter;
  // trips for current selection, available when day and week are selected
  tripsForSelection: Shipment[];

  // current page we have navigated to
  currentPage: Page;

  // all trips
  entries: Shipment[];

  projects: ShipmentProject[];
  packages: Package[];
  // is populated when user is signed in
  user: string;

  // token from backend
  token: string;

  isTransporter: boolean;

  // drilldown data
  selectionOptions: {
    trucksInData: string[];
    weeks: number[];
    days: Date[];
  };
};

export const state: State = {
  isInitialLoadComplete: false,
  isTransporter: false,
  selectionOptions: derived((state: State) => {
    const weekTmp: { [index: number]: number } = {};
    const trucks: { [index: string]: string } = {};

    // get all trucks in server side response
    /*
    if (state.entries && state.entries.length) {
      state.entries.forEach((d) => {
        //        weekTmp[d.levUke] = d.levUke;
        trucks[d.transport] = d.transport;
      });
    }
     */

    // get weeks (matching truckfilter if present)

    if (state.entries && state.entries.length) {
      state.entries.forEach((d) => {
        // if (!state.filter.truckType || state.filter.truckType ===
        // d.transport) {
        weekTmp[d.deliveryWeek] = d.deliveryWeek;
        // }
      });
    }

    let daysTmp: Date[] = [];
    if (state.selection.week) {
      state.entries
        .filter(
          (d) => d.deliveryWeek === state.selection.week /* &&
            (!state.filter.truckType || state.filter.truckType === d.transport)
            */
        )
        .forEach((d) => {
          daysTmp.push(d.deliveryDay);
        });
    }
    daysTmp = daysTmp.filter((date, i, self) => {
      return self.findIndex((d) => d.getTime() === date.getTime()) === i;
    });
    return {
      trucksInData: Object.values(trucks).sort(),
      weeks: Object.values(weekTmp).sort((a, b) => a - b),
      days: daysTmp.sort((a, b) => a.getTime() - b.getTime()),
    };
  }),

  tripsForSelection: derived((state: State) => {
    if (state.selection.day === undefined) return [];
    return state.entries.filter(
      (d) => d.deliveryDay.getUTCDate() === state.selection.day.getUTCDate()
    );
  }),

  selection: {
    week: null,
    day: null,
    trip: null,
  },
  filter: {
    year: new Date().getFullYear(),
    fromWeek: getWeek(new Date()),
    toWeek: getWeek(new Date()),
  },
  currentPage: Page.LOGIN,
  entries: [],
  projects: [],
  packages: [],
  user: null,
  token: null,
};
