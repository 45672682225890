import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { createUrl } from "../app/pages";
import { Page } from "../types/Page";

const MyButton = styled.a`
  background: #ca0000;
  color: white;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

type WeekPickerProps = {
  availableWeeks: number[];
};
export const WeekPicker: FunctionComponent<WeekPickerProps> = ({
  availableWeeks,
}) => {
  return (
    <Container>
      {availableWeeks.map((week: number) => {
        return (
          <MyButton
            key={week}
            href={createUrl(Page.HOME, { week })}
          >{`Leveringsuke ${week}`}</MyButton>
        );
      })}
    </Container>
  );
};
