import React, { FunctionComponent, useState } from "react";
import { Page } from "@frontend/common/src/elements/Page/Page";
import styled from "styled-components";
import { Oval } from "react-loader-spinner";

import { BreadCrumbs } from "../../components/BreadCrumbs";
import { WeekPicker } from "../../components/WeekPicker";
import { DayPicker } from "../../components/DayPicker";
import { DeliveryList } from "../../components/DeliveryList/DeliveryList";
import { TripFilter } from "../../components/TripFilter/TripFilter";
import { Page as PageConstant } from "../../types/Page";

import { ShipmentProject } from "../../types/Shipment";
import { TripList } from "../../components/TripList/TripList";

import { DeliveryModal } from "../../components/DeliveryModal/DeliveryModal";
import { useActions, useAppState, useEffects } from "../../overmind";
import { createUrl } from "../../app/pages";

const HeaderLink = styled.button`
  color: #fff;
  padding: 1rem;
  font-size: 1.25rem;
`;
export const HomePage: FunctionComponent = () => {
  const state = useAppState();
  const actions = useActions();
  const effects = useEffects();
  const [selectedDelivery, setSelectedDelivery] = useState<ShipmentProject>();
  const [printIsloading, setPrintIsloading] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const selectDelivery = (shipmentProject: ShipmentProject) => {
    setSelectedDelivery(shipmentProject);
    actions.fetchItems({
      shipmentId: state.selection.trip,
      projectId: shipmentProject.projID,
    });
  };
  return (
    <Page
      title={`Kjørelister ${state.user}`}
      headerItems={
        <div style={{ display: "flex", flexDirection: "row" }}>
          {!printIsloading ? (
            <HeaderLink
              onClick={() => {
                setPrintIsloading(true);
                actions.downloadHtml().finally(() => setPrintIsloading(false));
              }}
            >
              Skriv ut
            </HeaderLink>
          ) : (
            <Oval color="#C24E44" secondaryColor="#D9D9D9" strokeWidth="3" />
          )}
          {!downloadIsLoading ? (
            <HeaderLink
              onClick={() => {
                setDownloadIsLoading(true);
                actions.downloadCSV().then(() => setDownloadIsLoading(false));
              }}
            >
              Last ned
            </HeaderLink>
          ) : (
            <Oval
              height="50px"
              color="#C24E44"
              secondaryColor="#D9D9D9"
              strokeWidth="3"
            />
          )}
          <HeaderLink onClick={() => actions.logout()}>Logg ut</HeaderLink>
        </div>
      }
    >
      <TripFilter
        initialFilter={{ ...state.filter }}
        setSelectedDelivery={setSelectedDelivery}
        onFilterChanged={actions.onFilterChanged}
      />

      <BreadCrumbs
        setSelectedDelivery={setSelectedDelivery}
        selectedDelivery={selectedDelivery}
        year={state.filter.year}
        selection={state.selection}
      />

      {!state.selection.week ? (
        <WeekPicker availableWeeks={state.selectionOptions.weeks} />
      ) : null}

      {state.selection.week && !state.selection.day ? (
        <DayPicker
          entries={state.entries}
          days={state.selectionOptions.days}
          currentWeek={state.selection.week}
        />
      ) : null}

      {!state.selection.trip && state.selection.day ? (
        <TripList
          onRowSelected={(row) => {
            effects.router.navigateTo(
              createUrl(PageConstant.HOME, {
                week: state.selection.week,
                day: state.selection.day.toISOString().substring(0, 10),
                trip: row.shipmentId,
              })
            );
          }}
          trips={state.tripsForSelection}
        />
      ) : null}

      {state.selection.trip && !selectedDelivery ? (
        <DeliveryList
          deliveries={state.projects}
          onRowSelected={selectDelivery}
        />
      ) : null}

      {selectedDelivery ? (
        <DeliveryModal
          selectedDelivery={selectedDelivery}
          packages={state.packages}
        />
      ) : null}
    </Page>
  );
};
