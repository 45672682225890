import React, { FunctionComponent, useEffect, useState } from "react";
import { Grid } from "@frontend/common/src/elements/Grid/Grid";
import { Select } from "@frontend/common/src/components/Select/Select";
import { Filter } from "../../types/Filter";
import { ShipmentProject } from "../../types/Shipment";

type Props = {
  initialFilter: Filter;
  onFilterChanged(filter: Filter): void;
  setSelectedDelivery: (proj: ShipmentProject | null) => void;

  // trucksInData: string[];
};

const today = new Date();
const thisYear = today.getFullYear();

const yearOptions = [
  { label: (thisYear - 1).toString(), value: thisYear - 1 },
  { label: thisYear.toString(), value: thisYear },
];

if (today.getMonth() > 10) {
  yearOptions.push({ label: (thisYear + 1).toString(), value: thisYear + 1 });
}
const weekOptions: { label: string; value: any }[] = [];
for (let i = 1; i <= 52; i++) {
  weekOptions.push({ label: `Uke ${i}`, value: i });
}

export const TripFilter: FunctionComponent<Props> = ({
  onFilterChanged,
  initialFilter,
  setSelectedDelivery,
  // trucksInData,
}) => {
  const [filter, setFilter] = useState<Filter>(initialFilter);

  useEffect(() => {
    onFilterChanged(filter);
    setSelectedDelivery(undefined);
  }, [filter]);

  return (
    <Grid>
      <div
        style={{
          display: "flex",
          width: "30%",
          justifyContent: "space-between",
          marginBottom: "1em",
        }}
      >
        <Select
          label="År:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          options={yearOptions}
          selectedValue={filter.year}
          onSelectionChanged={(year) =>
            setFilter({
              ...filter,
              year,
            })
          }
        />
        <Select
          label="Uke fra og med:"
          options={weekOptions}
          selectedValue={filter.fromWeek}
          onSelectionChanged={(fromWeek) =>
            setFilter({
              ...filter,
              fromWeek,
            })
          }
        />
        <Select
          label="Uke til og med:"
          options={weekOptions}
          selectedValue={filter.toWeek}
          onSelectionChanged={(toWeek) =>
            setFilter({
              ...filter,
              toWeek,
            })
          }
        />

        {/*
                <Select
              label="Type bil:"
              options={trucksInData.map((t) => ({ value: t, label: t }))}
              selectedValue={filter.truckType}
              onSelectionChanged={(truckType) =>
                setFilter({
                  ...filter,
                  truckType,
                })
              }
            /> */}
      </div>
    </Grid>
  );
};
