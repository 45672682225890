import { Table, Column } from "@frontend/common/src/components/Table/Table";
import React, { FunctionComponent } from "react";
import { ShipmentProject } from "../../types/Shipment";

type ColumnKey = keyof ShipmentProject;

type Props = {
  deliveries: ShipmentProject[];
  columns?: ColumnKey[];
  onRowSelected: (row: ShipmentProject) => void;
};

const createColumns = (): Column<ShipmentProject>[] => {
  const allColumns: Column<ShipmentProject>[] = [
    {
      headerName: "Ordre",
      field: "projID",
      render: (row) => {
        return row.projID;
      },
    },
    {
      headerName: "Kundenavn",
      field: "customerName",
      render: (row) => {
        return (
          <div>
            <p>{row.customerName}</p>
          </div>
        );
      },
    },
    {
      headerName: "Merkes",
      field: "marked",
    },
    {
      headerName: "Postadresse",
      field: "city",
    },
    {
      headerName: "Adresse",
      field: "address",
    },
    {
      headerName: "Kunde ref",
      field: "contactPerson",
    },
    {
      headerName: "Tlf",
      field: "contactPhone",
    },
    {
      headerName: "Bredde",
      field: "width",
    },
    {
      headerName: "Høyde",
      field: "height",
    },
    {
      headerName: "Lengde",
      field: "length",
    },
    {
      headerName: "Volum",
      field: "volume",
    },
    {
      headerName: "Kraning",
      field: "lifting",
      render: (row) => {
        return row.lifting ? "Ja" : "Nei";
      },
    },
  ];

  return allColumns;
};

export const DeliveryList: FunctionComponent<Props> = ({
  deliveries,
  columns,
  onRowSelected,
}) => {
  const allColumns = createColumns();
  let columnsToUse = null;
  if (!columns) {
    columnsToUse = allColumns;
  } else {
    columnsToUse = columns.map((c) => allColumns.find((a) => a.field === c));
  }

  return (
    <Table
      keyField="turlinjeId"
      data={deliveries}
      columns={columnsToUse}
      availableColumns={allColumns}
      onRowSelected={onRowSelected}
    />
  );
};
