import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Page } from "@frontend/common/src/elements/Page/Page";
import { FormInput } from "@frontend/common/src/elements/FormInput/FormInput";
import { BlockButton } from "@frontend/common/src/elements/Buttons/Buttons.styles";
import { ButtonGroup } from "@frontend/common/src/elements/ButtonGroup/ButtonGroup.styles";
import { useTranslation } from "react-i18next";
import { useActions } from "../../overmind";

const loginSchema = yup.object().shape({
  username: yup.string().required("Vennligst fyll inn brukernavn"),
  password: yup.string().required("Vennligst fyll inn passord"),
});

type Props = {
  onLoggedIn: (user: any) => void;
};

type LoginCredentials = {
  username: string;
  password: string;
};

const buildVersion = process.env.REACT_APP_BUILD_VERSION;

export const LoginPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const actions = useActions();

  const { register, handleSubmit, errors } = useForm<LoginCredentials>({
    resolver: yupResolver(loginSchema),
  });

  const onSubmitData = async (data: LoginCredentials) => {
    actions.login({
      username: data.username,
      password: data.password,
    });
  };

  return (
    <Page title="Logg inn">
      <form style={{ padding: "24px 24px 0 24px", height: "auto" }}>
        <div style={{ maxWidth: "25rem" }}>
          <FormInput
            htmlFor="username"
            name="username"
            label={t("Email/username")}
            type="text"
            placeholder={t("Email/username")}
            defaultValue=""
            inputRef={register}
            error={errors.username?.message}
          />
          <FormInput
            htmlFor="password"
            name="password"
            label={t("Password")}
            type="password"
            placeholder={t("Password")}
            defaultValue=""
            inputRef={register}
            error={errors.password?.message}
          />

          <ButtonGroup>
            <BlockButton onClick={handleSubmit(onSubmitData)}>
              {t("Log in")}
            </BlockButton>
          </ButtonGroup>
        </div>
        <p style={{ marginTop: "1rem" }}> Versjon: {buildVersion}</p>
      </form>
    </Page>
  );
};
