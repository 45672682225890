/* eslint-disable no-param-reassign */
import jwt_decode from "jwt-decode";
import { addHours, parseISO } from "date-fns";
import { createUrl, getNavigationRoute } from "../app/pages";
import { Filter } from "../types/Filter";
import { Page } from "../types/Page";
import { UpdateTrip } from "../types/Trip";
import { Context } from "./index";
import { downloadAsCSV } from "../exporters/csv";
import { downloadAsHtml } from "../exporters/html";

export const openLogin = async ({ state }: Context) => {
  state.currentPage = Page.LOGIN;
};

export const openHome = async ({ state, effects }: Context, args: any) => {
  state.currentPage = Page.HOME;
  const isGuest = !state.user;
  if (isGuest) {
    return;
  }
  let day =
    args.params.day !== undefined ? parseISO(args.params.day) : undefined;
  if (day !== undefined) {
    day = addHours(day, 3);
  }

  const { trip } = args.params;
  state.selection = {
    week: parseInt(args.params.week, 10),
    day,
    trip,
  };
  // ensure the filter we apply against backend does not exclude our selection
  let { fromWeek } = state.filter;
  let { toWeek } = state.filter;
  const year = state.filter.year || new Date().getFullYear();

  if (state.filter.fromWeek > state.selection.week) {
    fromWeek = state.selection.week;
  }

  if (state.filter.toWeek < state.selection.week) {
    toWeek = state.selection.week;
  }

  state.filter = {
    fromWeek,
    toWeek,
    year,
    truckType: state.filter.truckType,
  };
  const entries = await effects.api.getTrips(
    state.filter.year,
    state.filter.fromWeek,
    state.filter.toWeek
  );
  if (entries !== undefined) {
    state.entries = entries;
  }
  if (trip !== undefined) {
    const projects = await effects.api.getShipmentProjects(trip);
    if (projects !== undefined) {
      state.projects = projects;
    }
  }
  /*
  if (state.filter.truckType)
  {
    state.entries = entries.filter(e => e.transport == state.filter.truckType);
  }
  else
  {
    state.entries = entries;
  } */
};

// filter changed, so set new, clear selection and let navigation sort out loading of data
export const onFilterChanged = async (
  { state, effects }: Context,
  filter: Filter
) => {
  state.filter = filter;
  state.selection = {};
  state.projects = [];
  state.packages = [];
  effects.router.navigateTo(createUrl(Page.HOME));
};

// update a trip
export const updateTrip = async (
  { actions, effects }: Context,
  // eslint-disable-next-line no-shadow
  updateTrip: UpdateTrip
) => {
  await effects.api.updateTrip(updateTrip);
  actions.reloadData();
};

// reloads data
export const reloadData = async ({ state, effects }: Context) => {
  state.entries = await effects.api.getTrips(
    state.filter.year,
    state.filter.fromWeek,
    state.filter.toWeek
  );
};

export const fetchItems = async (
  { state, effects }: Context,
  payload: { shipmentId: string; projectId: string }
) => {
  const items = await effects.api.getShipmentItems(
    payload.shipmentId,
    payload.projectId
  );
  if (items !== undefined) {
    state.packages = items;
  }
};

export const downloadCSV = async ({ state, effects }: Context) => {
  const { entries } = state;
  const projects = await effects.api.getProjectsForMultipleShipments(
    entries.map((ent) => ent.shipmentId)
  );
  downloadAsCSV(entries, projects);
};

export const downloadHtml = async ({ state, effects }: Context) => {
  const { entries } = state;
  const projects = await effects.api.getProjectsForMultipleShipments(
    entries.map((ent) => ent.shipmentId)
  );
  downloadAsHtml(entries, projects);
};
export const login = async (
  { effects, actions }: any,
  credentials: { username: string; password: string }
) => {
  try {
    const token = await effects.api.login(
      credentials.username,
      credentials.password
    );
    if (token) {
      await actions.onLoggedIn(token);
      effects.router.navigateTo(createUrl(Page.HOME));
    } else {
      effects.toast("Innlogging feilet");
    }
  } catch (errorMessage) {
    effects.toast(errorMessage, 5000);
  }
};

// called on successful login
export const onLoggedIn = async (
  { state, effects }: Context,
  token: string
) => {
  const decodedToken: { department: string; transportor: string } =
    jwt_decode(token);
  state.token = token;
  effects.tokenService.set(token);
  state.user = decodedToken.department;
  state.isTransporter = decodedToken.transportor.length > 0;
};

export const logout = async ({ state, effects }: Context) => {
  try {
    state.selection = {};
    state.entries = [];
    state.user = null;
    state.token = null;
    effects.tokenService.clear();

    // perform hard navigate to clear state.
    window.location.href = createUrl(Page.LOGIN);
  } catch (error: any) {
    alert(error.message);
  }
};

export const onInitializeOvermind = async ({
  state,
  actions,
  effects,
}: Context) => {
  const token = effects.tokenService.get();

  if (token) {
    let isTokenValid = false;
    try {
      isTokenValid = await effects.api.checkToken(); // TODO: FIX ME
    } catch (error) {
      await effects.tokenService.clear();
    }
    if (isTokenValid) {
      try {
        await actions.onLoggedIn(token);
      } catch (error) {
        await effects.tokenService.clear();
      }
    } else {
      await effects.tokenService.clear();
    }
  }

  state.isInitialLoadComplete = true;
  const { router } = effects;

  router.route(getNavigationRoute(Page.HOME), actions.openHome);
  router.route(getNavigationRoute(Page.LOGIN), actions.openLogin);

  try {
    router.start();
  } catch (error) {
    console.error(error);
  }
};
