import axios from "axios";

// eslint-disable-next-line no-shadow
export enum Verb {
  POST = "POST",
  PUT = "PUT",
  GET = "GET",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
  PATCH = "PATCH",
}

export type Endpoint = {
  path: string;
  verb: Verb;
};

export abstract class RestClient {
  protected abstract getRootPath(): string;

  public makeRequest(
    route: Endpoint,
    body: any = null,
    cancelToken: any = null
  ): Promise<any> {
    if (["PUT", "DELETE", "POST", "GET"].indexOf(route.verb) === -1) {
      return Promise.reject(new Error("Invalid verb"));
    }
    if (!route.path) {
      return Promise.reject(new Error("Path was not specified"));
    }
    const params: any = {
      method: route.verb,
      url: this.getRootPath() + RestClient.replaceParameters(route.path, body),
    };

    if (body !== null) {
      params.data = body;
    }
    if (cancelToken !== null) {
      params.cancelToken = cancelToken.token;
    }
    return axios(params).catch((error) => {
      if (axios.isCancel(error)) {
        return [];
      }
      return Promise.reject(
        error.response?.data?.message || error.message || ""
      );
    });
  }

  private static replaceParameters(url: string, data: any) {
    if (!data) {
      return url;
    }
    // match patterns like foo/{bar}/{?baz}{/?foobar} and replace {bar} with supplied params
    const paramsToReplace = url.match(/\{(([^?}]+)?\?)?[a-z0-9-_]+\}/gi);
    if (paramsToReplace) {
      let lastMissingOptionalKey;
      for (let i = 0; i < paramsToReplace.length; i++) {
        const key = paramsToReplace[i].slice(1, -1);
        const parts = key.split("?");
        const isOptional = parts.length > 1;
        const param = parts.pop();
        const prePath = parts.pop();
        // eslint-disable-next-line no-prototype-builtins
        const hasParamToReplaceKey = data.hasOwnProperty(param);
        let paramToReplaceKey = "";

        if (isOptional && hasParamToReplaceKey && lastMissingOptionalKey) {
          throw new Error(
            `Missing previous optional key ${lastMissingOptionalKey}`
          );
        }
        if (!isOptional && !hasParamToReplaceKey) {
          throw new Error(`Missing parameter ${param}`);
        } else if (hasParamToReplaceKey) {
          paramToReplaceKey = data[param];
          if (prePath) {
            paramToReplaceKey = prePath + paramToReplaceKey;
          }
          // eslint-disable-next-line no-param-reassign
          delete data[param];
        }

        // eslint-disable-next-line no-param-reassign
        url = url.replace(`{${key}}`, paramToReplaceKey);

        if (isOptional && !hasParamToReplaceKey) {
          lastMissingOptionalKey = param;
        }
      }
    }
    return url;
  }
}
