import { RestClient as BaseRestClient } from "@frontend/common/src/rest/RestClient";
import axios from "axios";

const ROOT_ENDPOINT = process.env.REACT_APP_API_BASE;

export class RestClient extends BaseRestClient {
  constructor() {
    super();

    console.log(
      "RestClient.token",
      axios.defaults.headers.common.Authorization
    );
  }

  // eslint-disable-next-line class-methods-use-this
  protected getRootPath(): string {
    return ROOT_ENDPOINT;
  }
}
