import { Table, Column } from "@frontend/common/src/components/Table/Table";

import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { EditTripModal } from "../EditTripModal/EditTripModal";
import { Shipment } from "../../types/Shipment";
import { useAppState } from "../../overmind";

type ColumnKey = keyof Shipment;

type Props = {
  trips: Shipment[];
  columns?: ColumnKey[];
  onRowSelected: (row: Shipment) => void;
};

export const EditButton = styled("button")`
  background: #ca0000;
  color: antiquewhite;
  padding: 0.2rem;
  border-radius: 0.2rem;
`;

const createColumns = (
  setTripToEdit: (trip: Shipment) => void,
  isTransporter: boolean
): Column<Shipment>[] => {
  const allColumns: Column<Shipment>[] = [
    {
      headerName: "Tur nr",
      field: "shipmentId",
    },
    {
      headerName: isTransporter ? "Fabrikk" : "Transportør",
      field: "site",
      render: (row) => {
        return isTransporter ? row.site : row.transporter;
      },
    },

    {
      headerName: "Sjåfør",
      field: "driver",
    },
    {
      headerName: "Bil",
      field: "carName",
    },
    {
      headerName: "Status",
      field: "shipmentStatus",
    },
    {
      headerName: "",
      field: "shipmentId",
      render: (row) => {
        return (
          <EditButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setTripToEdit(row);
            }}
          >
            Endre
          </EditButton>
        );
      },
    },
  ];

  return allColumns;
};

export const TripList: FunctionComponent<Props> = ({
  trips,
  columns,
  onRowSelected,
}) => {
  const state = useAppState();
  const [tripToEdit, setTripToEdit] = useState<Shipment>(null);
  const allColumns = createColumns(setTripToEdit, state.isTransporter);
  let columnsToUse = null;
  if (!columns) {
    columnsToUse = allColumns;
  } else {
    columnsToUse = columns.map((c) => allColumns.find((a) => a.field === c));
  }

  return (
    <>
      <Table
        keyField="shipmentId"
        data={trips}
        availableColumns={allColumns}
        columns={columnsToUse}
        onRowSelected={onRowSelected}
      />
      {tripToEdit ? (
        <EditTripModal trip={tripToEdit} onClose={() => setTripToEdit(null)} />
      ) : null}
    </>
  );
};
