import page from "page";
import axios from "axios";
import Toastify from "toastify-js";
import { RouteContext } from "../types/RouteContext";
import { TripService } from "../services/TripService";
import "toastify-js/src/toastify.css";

export const api = new TripService();

export const router = {
  route(
    url: string,
    action: (payload: RouteContext<{} | void>, next: any) => void
  ) {
    page(url, ({ params, path, pathname, routePath }, next) => {
      action({ params, path, pathname, routePath }, next);
    });
  },
  redirect: page.redirect,
  start: () =>
    page.start({
      click: true,
    }),
  navigateTo: (path: string) => page(path),
};

export const tokenService = {
  get: () => {
    const token = localStorage.getItem("api_token") || null;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    return token;
  },
  set: (token: string) => {
    localStorage.setItem("api_token", token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  clear: () => {
    localStorage.removeItem("api_token");
    axios.defaults.headers.common.Authorization = "";
  },
};

export const toast = (text: string, duration: number = 3000) => {
  Toastify({
    text,
    duration,
    gravity: "bottom",
    backgroundColor: "#CA0000",
  }).showToast();
};
