/* eslint-disable import/no-duplicates */
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { createUrl } from "../app/pages";
import { Page } from "../types/Page";
import { Selection } from "../types/Selection";
import { ShipmentProject } from "../types/Shipment";

const BreadCrumbContainer = styled.ul`
  list-style: none;
  overflow: hidden;
  font: 18px Sans-Serif;
  margin-bottom: 1em;
`;

export const Lead = styled.li`
  float: left;
  padding: 10px 0 10px 3rem;
`;

const Crumb = styled.li`
  float: left;

  a {
    color: white;
    text-decoration: none;
    padding: 10px 0 10px 65px;
    background: #430a03;
    position: relative;
    display: block;
    float: left;
  }

  a::after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
    border-bottom: 50px solid transparent;
    border-left: 30px solid #430a03;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
  }

  a::before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }

  &:first-child a {
    padding-left: 10px;
  }
  &:nth-child(2) a {
    background: #ca0000;
  }
  &:nth-child(2) a:after {
    border-left-color: #ca0000;
  }
  &:nth-child(3) a {
    background: #c24e44;
  }
  &:nth-child(3) a:after {
    border-left-color: #c24e44;
  }
  &:nth-child(4) a {
    background: hsla(7, 91%, 65%, 1);
  }
  &:nth-child(4) a:after {
    border-left-color: hsla(7, 91%, 65%, 1);
  }
  &:nth-child(5) a {
    background: hsla(7, 91%, 75%, 1);
  }
  &:nth-child(5) a:after {
    border-left-color: hsla(7, 91%, 75%, 1);
  }
  &:last-child a {
    color: white;
    pointer-events: none;
    cursor: default;
  }

  &:last-child a::after {
  }
`;

type BreadCrumbProps = {
  selection: Selection;
  year: number;
  selectedDelivery: ShipmentProject;
  setSelectedDelivery: (proj: ShipmentProject | null) => void;
};
export const BreadCrumbs: FunctionComponent<BreadCrumbProps> = ({
  selection,
  year,
  setSelectedDelivery,
  selectedDelivery,
}) => {
  return (
    <BreadCrumbContainer>
      <Crumb>
        <a onClick={() => setSelectedDelivery(undefined)} href="/turer">
          {year}
        </a>
      </Crumb>
      {selection && selection.week ? (
        <Crumb>
          <a
            onClick={() => setSelectedDelivery(undefined)}
            href={createUrl(Page.HOME, { week: selection.week })}
          >
            Uke {selection.week}
          </a>
        </Crumb>
      ) : (
        <Lead>Velg uke</Lead>
      )}
      {selection && selection.day ? (
        <Crumb>
          <a
            onClick={() => setSelectedDelivery(undefined)}
            href={createUrl(Page.HOME, {
              week: selection.week,
              day: selection.day.toISOString().substring(0, 10),
            })}
          >
            Dag {format(selection.day, "EEEE d. MMM", { locale: nb })}
          </a>
        </Crumb>
      ) : null}
      {selection && selection.trip ? (
        <Crumb>
          <a
            onClick={() => setSelectedDelivery(undefined)}
            href={createUrl(Page.HOME, {
              week: selection.week,
              day: selection.day.toISOString().substring(0, 10),
              trip: selection.trip,
            })}
          >
            {selection.trip}
          </a>
        </Crumb>
      ) : null}
      {selectedDelivery && (
        <Crumb>
          <a>{selectedDelivery.projID}</a>
        </Crumb>
      )}
      {selection && selection.week && !selection.day ? (
        <Lead>Velg dag</Lead>
      ) : null}
      {selection && selection.day && !selection.trip ? (
        <Lead>Velg tur</Lead>
      ) : null}
    </BreadCrumbContainer>
  );
};
